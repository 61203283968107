import React from "react"
import SEO from '../components/seo';
import Layout from '../containers/layout/layout'
import Banner from '../containers/company/banner'
import About from '../containers/company/about'
import Team from '../containers/company/team'
import Testimonial from '../containers/company/testimonial'

export default () => (
    <Layout>
        <SEO 
            title="Маркетинговое агентство полного цикла ostrovsky-marketing"
            description={
                `Любим свою работу, постоянно развиваемся сами и помогаем 
                развиваться вам. Творческий подход и оценимый эффект.`
            }
        />
        <Banner/>
        <About/>
        <Team/>
        <Testimonial/>
    </Layout>
)
